export { GetBrandConfig }

const countryBrandMapping = {
    "MY": ["BB", "CL", "EL", "MAC", "LM", "JML", "TF", "OR", "CCS", "LL"],
    "TH": ["BB", "CL", "EL", "MAC", "LM", "JML", "TF", "LL", "OR", "AV", "CCS"],
    "PH": ["MAC", "BB", "CL", "EL", "OR","JML","TF","LM", "LL", "BBC"],
}

const brandMapping = {
    'BB': {
        name: 'bobbibrown',
        display: 'Bobbi Brown',
        myDisplay: 'BOBBI BROWN',
        header: '/img/BB_Logotype_SingleLine_Black_CMYK.png',
        footer: '/img/BB_Logotype_SingleLine_Black_CMYK.png',
        color: '#ffffff',
    },
    'CL': {
        name: 'clinique',
        display: 'Clinique',
        myDisplay: 'CLINIQUE',
        header: '/img/CLINIQUE.png',
        footer: '/img/CLINIQUE.png',
        color: '#ffffff',
    },
    'EL': {
        name: 'esteelauder',
        display: 'Estée Lauder',
        myDisplay: 'ESTÉE LAUDER',
        header: '/img/esteelauder_logo.png',
        footer: '/img/esteelauder_logo.png',
        color: '#ffffff',
    },
    'MAC': {
        name: 'mac',
        display: 'MAC',
        myDisplay: 'M.A.C',
        header: '/img/MAC.png',
        footer: '/img/MAC.png',
        color: '#ffffff',
    },
    'LM': {
        name: 'lamer',
        display: 'La Mer',
        myDisplay: 'LA MER',
        header: '/img/La_Mer_Logo_Green.png',
        footer: '/img/La_Mer_Logo_Green.png',
        color: '#ffffff',
    },
    'JML': {
        name: 'jomalone',
        display: 'JO MALONE LONDON',
        myDisplay: 'JO MALONE LONDON',
        header: '/img/jomalone_logo_header.png',
        footer: '/img/jomalone_logo_footer.png',
        color: '#ffffff',
    },
    'TF': {
        name: 'tomford',
        display: 'Tom Ford Beauty',
        myDisplay: 'TOM FORD BEAUTY',
        header: '/img/TF.png',
        footer: '/img/TF.png',
        color: '#ffffff',
    },
    'LL': {
        name: 'lelabo',
        display: 'LE LABO',
        myDisplay: 'LE LABO',
        header: '/img/LL.png',
        footer: '/img/LL.png',
        color: '#ffffff',
    },
    'OR': {
        name: 'origins',
        display: 'Origins',
        myDisplay: 'ORIGINS',
        header: '/img/origins.png',
        footer: '/img/origins.png',
        color: '#ffffff',
    },
    'AV': {
        name: 'aveda',
        display: 'Aveda',
        myDisplay: 'AVEDA',
        header: '/img/Aveda.png',
        footer: '/img/Aveda.png',
        color: '#ffffff',
    },
    'CCS': {
        name: 'companycorporatesales',
        display: 'The Cosmetics Company Store',
        myDisplay: 'THE COSMETICS COMPANY STORE',
        header: '/img/CCS.png',
        footer: '/img/CCS.png',
        color: '#ffffff',
    },
    'BBC': {
        name: 'multibrand',
        display: 'Multi Brand',
        myDisplay: 'Multi Brand',
        header: '/img/BBC.png',
        footer: '/img/BBC.png',
        color: '#ffffff',
    },
}

const brandLinkConfig = {
    "MY": {
        'BB': {
            privacyPolicy: 'https://www.bobbibrown.com.my/privacy-policy',
            termsAndConditions: 'https://www.bobbibrown.com.my/loyalty-terms-conditions',
        },
        'CL': {
            privacyPolicy: 'https://www.clinique.com.my/customer-care/general-info/privacy-policy',
            termsAndConditions: 'https://www.clinique.com.my/customer-care/general-info/loyalty-terms-conditions',
        },
        'EL': {
            privacyPolicy: 'https://www.esteelauder.com.my/privacy',
            termsAndConditions: 'https://www.esteelauder.com.my/terms-conditions-loyalty',
        },
        'MAC': {
            privacyPolicy: 'https://www.maccosmetics.com.my/privacy-policy',
            termsAndConditions: 'https://www.maccosmetics.com.my/mac-loyalty-program'
        },
        'LM': {
            privacyPolicy: 'https://www.lamer.com.my/customer-service-privacy-policy',
            termsAndConditions: 'https://www.lamer.com.my/loyalty-program/terms-and-conditions'
        },
        'JML': {
            privacyPolicy: 'https://www.jomalone.com.my/privacy-policy',
            termsAndConditions: ''
        },
        'TF': {
            privacyPolicy: 'HTTP://WWW.TOMFORDBEAUTY.COM.MY/PRIVACY.HTML',
            termsAndConditions: ''
        },
        'LL': {
            privacyPolicy: 'https://sway.cloud.microsoft/Rlp9kUYeVXWkrvNj?ref=Link&loc=play',
            termsAndConditions: ''
        },
        'OR': {
            privacyPolicy: 'https://www.origins.my/customer-service-privacy-policy',
            termsAndConditions: 'https://www.origins.my/origins-loyalty-program-terms-conditions'
        },
        'AV': {
            privacyPolicy: 'https://www.aveda.co.th/customer-service-privacy',
            termsAndConditions: 'https://www.aveda.co.th/customer-service-terms'
        },
        'CCS': {
            privacyPolicy: 'https://www.elcompanies.com/en/privacy-policy',
            termsAndConditions: ''
        },
    },
    "TH": {
        'BB': {
            privacyPolicy: 'https://www.bobbibrown.co.th/customer-service-corporate#corporate-privacy',
            termsAndConditions: 'https://www.bobbibrown.co.th/customer-service-corporate#corporate-terms',
        },
        'CL': {
            privacyPolicy: 'https://www.clinique.co.th/customer-care/general-info/privacy-policy',
            termsAndConditions: 'https://www.clinique.co.th/customer-care/general-info/terms-conditions',
        },
        'EL': {
            privacyPolicy: 'https://www.esteelauder.co.th/privacy',
            termsAndConditions: 'https://www.esteelauder.co.th/terms-conditions',
        },
        'MAC': {
            privacyPolicy: 'https://www.maccosmetics.co.th/privacy-policy',
            termsAndConditions: 'https://www.maccosmetics.co.th/terms-conditions'
        },
        'LM': {
            privacyPolicy: 'https://www.lamer.co.th/customer-service-privacy-policy',
            termsAndConditions: 'https://www.lamer.co.th/customer-service-terms-conditions'
        },
        'JML': {
            privacyPolicy: 'https://www.jomalone.co.th/privacy-policy',
            termsAndConditions: ''
        },
        'TF': {
            privacyPolicy: '',
            termsAndConditions: ''
        },
        'LL': {
            privacyPolicy: '',
            termsAndConditions: ''
        },
        'OR': {
            privacyPolicy: 'https://www.origins.co.th/customer-service-privacy-policy',
            termsAndConditions: ''
        },
        'AV': {
            privacyPolicy: 'https://www.aveda.co.th/customer-service-privacy',
            termsAndConditions: ''
        },
        'CCS': {
            privacyPolicy: 'https://shop.thecompanystore.asia/th/th-privacy-policy',
            termsAndConditions: ''
        },
    },
    "PH": {
        'MAC': {
            privacyPolicy: 'https://www.maccosmetics.com.ph/privacy-policy',
            termsAndConditions: '',
        },
        'BB': {
            privacyPolicy: 'https://www.bobbibrown.com.ph/privacy-policy',
            termsAndConditions: '',
        },
        'CL': {
            privacyPolicy: 'https://www.clinique.com.ph/privacy-policy',
            termsAndConditions: '',
        },
        'EL': {
            privacyPolicy: 'https://www.esteelauder.com.ph/privacy-policy#:~:text=If%20you%20provide%20us%20with,consent%20to%20our%20intended%20use',
            termsAndConditions: ''
        },
        'OR': {
            privacyPolicy: 'https://www.origins.com.ph/customer-service-privacy-policy#:~:text=If%20you%20provide%20us%20with,consent%20to%20our%20intended%20use',
            termsAndConditions: ''
        },
        'JML': {
            privacyPolicy: 'https://www.jomalone.com.ph/privacy-policy',
            termsAndConditions: ''
        },
        'TF': {
            privacyPolicy: 'https://www.elcompanies.com/en/privacy/privacy-policy-sdk-pages-holder/philippines-all-brands-consumer-privacy-policy-english',
            termsAndConditions: ''
        },
        'LM': {
            privacyPolicy: 'https://www.lamer.com.ph/customer-service-privacy-policy',
            termsAndConditions: ''
        },
        'LL': {
            privacyPolicy: 'https://www.elcompanies.com/en/privacy/privacy-policy-sdk-pages-holder/philippines-all-brands-consumer-privacy-policy-english',
            termsAndConditions: ''
        },
        'BBC': {
            privacyPolicy: 'https://www.elcompanies.com/en/privacy/privacy-policy-sdk-pages-holder/philippines-all-brands-consumer-privacy-policy-english',
            termsAndConditions: ''
        },
    }
}

function GetBrandConfig(country, brand) {
    if (!(countryBrandMapping[country] && countryBrandMapping[country].find(i => i == brand))) {
        return null;
    }

    var data = {
        abbr: brand,
        ...brandMapping[brand],
        ...brandLinkConfig[country]?.[brand]
    }

    return data
}